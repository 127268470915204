import * as dom from '../utils/dom';

const BACKGROUND_IMAGE = 'ovalEvening.jpg';

dom.on.ready(() => {
  const homeMain = dom.el.find('#home-main');
  const { default: imageUrl } = require(`../images/${BACKGROUND_IMAGE}`);

  dom.el.attr.set(homeMain, {
    style: `background-image: url(${imageUrl})`,
  });
});
