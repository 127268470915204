import { __, keys, curryN } from 'ramda';

const listen = curryN(3,
  (evt, handler, el) => el.addEventListener(evt, handler),
);

const ready = (handler, el = document) =>
  listen('DOMContentLoaded', handler, el);

const EVENT_HANDLERS = [
  'click',
  'input',
  'change',
  'submit',
  'scroll',
  'keyup',
  'keydown',
  'mouseenter',
  'mouseleave',
  'drag',
  'dragend',
  'dragenter',
  'dragexit',
  'dragleave',
  'dragover',
  'dragstart',
  'drop',
];

export const on = EVENT_HANDLERS.reduce((o, evt) => ({
  ...o, [evt]: listen(evt),
}), { ready });

const findById = id => document.getElementById(id);
const findByClass = name => [...document.getElementsByClassName(name)];
const findBySelector = selector => [...document.querySelectorAll(selector)];

const findEl = s => {
  if (s.match(/^#(\w|\d|-|_)+$/i)) return findById(s.slice(1));
  if (s.match(/^\.(\w|\d|-|_)+$/i)) return findByClass(s.slice(1));

  return findBySelector(s);
};

const hasClass = (el, name) => el.classList.contains(name);

const addClass = (el, ...classes) =>
  classes.forEach(name => {
    if (!hasClass(el, name)) el.classList.add(name);
  });

const dropClass = (el, ...classes) =>
  classes.forEach(name => {
    if (hasClass(el, name)) el.classList.remove(name);
  });

const changeClass = (el, { add = [], drop = [] } = {}) => {
  addClass(el, ...add);
  dropClass(el, ...drop);
};

const hasAttr = (el, attr) => el.hasAttribute(attr);

const getAttr = (el, attr) =>
  hasAttr(el, attr) ? el.getAttribute(attr) : undefined;

const setAttr = (el, attrs = {}) =>
  keys(attrs).forEach(key => el.setAttribute(key, attrs[key]));

const dropAttr = (el, ...attrs) =>
  attrs.forEach(key => {
    if (getAttr(el, key)) el.removeAttribute(key);
  });

const getAria = (el, key) => getAttr(el, `aria-${key}`);
const setAria = (el, key, val) => setAttr(el, { [`aria-${key}`]: val });

const getData = (el, key) => getAttr(el, `data-${key}`);
const setData = (el, key, val) => setAttr(el, { [`data-${key}`]: val });

const createElement = (tag, opts = {}, html = '') => {
  const element = document.createElement(tag);
  const { id, class: classes, ...attrs } = opts;

  if (id) element.id = id;
  if (classes) addClass(element, ...classes);
  if (attrs) setAttr(element, attrs);
  if (html) element.innerHTML = html;

  return element;
};

export const el = {
  on,
  find: findEl,
  class: {
    has: hasClass,
    add: addClass,
    drop: dropClass,
    change: changeClass,
  },
  attr: {
    has: hasAttr,
    get: getAttr,
    set: setAttr,
    drop: dropAttr,
  },
  value: {
    get: ({ value }) => value,
    set: (e, v = '') => e.value = v,
  },
  checked: {
    set: (e, v = true) => e.checked = v,
    unset: (e, v = false) => e.checked = v,
  },
  aria: {
    get: getAria,
    set: setAria,
  },
  data: {
    get: getData,
    set: setData,
  },
  create: createElement,
};
